import { useEffect, useRef, useState } from "react";
import { IScheduledQuery } from "../../../models/ScheduledQueries/IScheduledQuery";
import { Col, Row, Spinner } from "reactstrap";
import TitleWithBackButton from "../../../components/TitleWithBackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PromptWithModal from "../../../components/PromptWithModal";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { ScheduledQueryHeaderCard } from "./components/ScheduledQueryHeaderCard";
import { JobFrequencyUnit } from "../../../models/Cache/JobFrequencyUnit";
import { JobType } from "../../../models/Cache/JobType";
import { JobStatus } from "../../../models/Cache/JobStatus";
import { useMutation } from "@tanstack/react-query";
import { getScheduledQuery } from "../api/getScheduledQuery";
import Page500 from "../../auth/Page500";
import Loader from "../../../components/Loader";
import { RunHistoryCard } from "./components/RunHistoryCard";
import { ScheduledQueryDetailsCard } from "./components/ScheduledQueryDetailsCard";
import AdvancedSettingsCard from "./components/AdvancedSettingsCard";
import { SQLCodeCard } from "./components/SQLCodeCard";
import { DestinationWriteScheme } from "../../../models/ScheduledQueries/DestinationWriteScheme";
import { createScheduledQuery } from "../api/createScheduledQuery";
import { ICreateScheduledQuery } from "../../../models/ScheduledQueries/ICreateScheduledQuery";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { DeleteJobModal } from "../modals/DeleteJobModal";
import { updateScheduledQuery } from "../api/updateScheduledQuery";
import { IUpdateScheduledQuery } from "../../../models/ScheduledQueries/IUpdateScheduledQuery";
import { generateDefaultNumberedName } from "../../../utility/generateDefaultNumberedName";
import { jobsConstants } from "../Jobs.constants";
import { useJobsFunctions } from "../useJobsFunctions";
import { JobRunType } from "src/models/Cache/JobRunType";
import { IQueryRecord } from "../../../models";
import { ErrorNotificationBanner } from "./components/ErrorNotificationBanner";
import { listWorkspaces } from "../../../api/Workspaces/listWorkspaces";
import { getWorkspaceNameFromQuery } from "../../dataExplorer/RightPanel/getWorkspaceNamefromQuery";

interface IJobData {
  name: string;
  queryText: string;
}

export function EditScheduledQuery() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const [scheduledQueryDetails, setScheduledQueryDetails] =
    useState<IScheduledQuery>({} as IScheduledQuery);
  const [isAddJob, setIsAddJob] = useState(false);
  const [loadingJobDetails, setLoadingJobDetails] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false);
  const [errorLog, setErrorLog] = useState<IQueryRecord | undefined>(undefined);

  const {
    deleteJob,
    getScheduledQueryList,
    getJobErrorLogsAsync,
    isJobsProcessing,
  } = useJobsFunctions();

  const { mutateAsync: getScheduledQueryDetails } = useMutation({
    mutationKey: ["/scheduledquery/get"],
    mutationFn: getScheduledQuery,
    onError: (error) => {
      return <Page500 error={error} />;
    },
    onSettled: () => setLoadingJobDetails(false),
  });

  const { mutateAsync: saveNewScheduledQuery, isPending: isSavingNew } =
    useMutation({
      mutationKey: ["/scheduledquery/create"],
      mutationFn: createScheduledQuery,
      onSuccess: (response) => {
        handleSuccessfulCreate(response);
      },
      onError: (error) => {
        ToastrError("Failed to create Scheduled Query", error.message);
      },
    });

  function handleSuccessfulCreate(response: IScheduledQuery) {
    // We have to manually set these formik values after creation, since they are not updated by fields
    formikRef.current?.setValues({
      ...formikRef.current?.values,
      created: response.created,
      id: response.id,
      lastModified: response.lastModified,
    });

    setScheduledQueryDetails(response);
    setIsAddJob(false);
    setUnsavedChanges(false);
    window.history.replaceState(
      {},
      "",
      `/jobs/editScheduledQuery/${response.id}`,
    );

    ToastrSuccess(
      "Scheduled Query successfully created",
      `Your Scheduled Query, ${formikRef.current?.values.name}, was successfully created.`,
    );
  }

  const {
    mutateAsync: saveUpdatedScheduledQuery,
    isPending: isUpdatingExisting,
  } = useMutation({
    mutationKey: ["scheduledquery/update"],
    mutationFn: updateScheduledQuery,
    onSuccess: (data) => {
      setScheduledQueryDetails(data);
      setUnsavedChanges(false);
      ToastrSuccess(
        "Scheduled Query successfully saved",
        `Your Scheduled Query, ${formikRef.current?.values.name}, was successfully updated.`,
      );
    },
    onError: (error) => {
      ToastrError("Failed to update Scheduled Query", error.message);
    },
  });

  const {
    mutateAsync: getWorkspaceListAsync,
    isPending: gettingWorkspaceList,
  } = useMutation({
    mutationKey: ["/workspaceList"],
    mutationFn: listWorkspaces,
    onError: (error) => {
      ToastrError("Failed to get workspaces", error.message);
    },
  });

  async function getWorkspaceId(query: string) {
    const workspaceName = getWorkspaceNameFromQuery(query);
    const workspaceList = await getWorkspaceListAsync();
    const workspaceId = workspaceList.workspaces?.find(
      (ws) => ws.name?.toLowerCase() === workspaceName,
    )?.id;
    return workspaceId;
  }

  useEffect(() => {
    const initializeAsync = async () => {
      let details: IScheduledQuery;
      const jobData = location.state as IJobData;
      if (location.state) {
        setIsAddJob(true);
        const scheduledQueryList = await getScheduledQueryList();
        details = {
          id: jobsConstants.DEFAULT_JOB_ID,
          name: generateDefaultNumberedName(
            "ScheduledQuery",
            scheduledQueryList?.list ?? [],
          ),
          destinationWriteScheme: DestinationWriteScheme.Overwrite,
          destinationConnection: "",
          destinationConnectionName: "",
          destinationSchema: "",
          destinationTable: "",
          jobFrequency: 1,
          jobFrequencyUnit: JobFrequencyUnit.Day,
          query: jobData.queryText,
          enabled: true,
          logVerbosity: 2,
          definedNextRun: new Date(new Date().getTime() + 60000).toISOString(), // Sets the default date 1 minute in the future to avoid null issues
          created: "0001-01-01T00:00:00Z",
          lastModified: "0001-01-01T00:00:00Z",
          jobType: JobType.ScheduledQuery,
          status: {
            lastRun: "0001-01-01T00:00:00Z",
            lastRunDuration: 0,
            info: "",
            status: JobStatus.JOB_CREATED,
            nextRun: "0001-01-01T00:00:00Z",
            runType: JobRunType.SCHEDULED,
          },
        };
        setScheduledQueryDetails(details);
        setLoadingJobDetails(false);
      } else {
        details = await getScheduledQueryDetails({
          queryId: params.scheduledQueryId!,
        });
        await processScheduledQueryDetails(details);
      }
    };
    initializeAsync();
  }, []); // eslint-disable-line

  const processScheduledQueryDetails = async (details: IScheduledQuery) => {
    setScheduledQueryDetails(details);

    if (details.status?.status === JobStatus.JOB_FAILED) {
      const newErrorLogs = await getJobErrorLogsAsync(
        details.status.lastRunId ?? "",
      );
      if (
        newErrorLogs &&
        newErrorLogs.queries &&
        newErrorLogs.queries.length > 0
      ) {
        setErrorLog(newErrorLogs.queries[0]);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("A name is required."),
    jobFrequency: Yup.number()
      .required("A job frequency is required.")
      .moreThan(0, "Value cannot equal 0."),
    definedNextRun: Yup.string().required("A start date is required."),
    destinationConnection: Yup.string().required(
      "A destination connection is required.",
    ),
    destinationSchema: Yup.string().required(
      "A destination schema is required.",
    ),
    destinationTable: Yup.string().required("A destination table is required."),
  });

  const onSubmit = async (values: FormikValues) => {
    // The ICreateScheduledQuery object contains parameters shared between create & update models, so we start with it as the base
    const createRequest: ICreateScheduledQuery = {
      name: values.name,
      destinationWriteScheme: values.destinationWriteScheme,
      destinationConnection: values.destinationConnection,
      destinationSchema: values.destinationSchema,
      destinationTable: values.destinationTable,
      jobFrequency: values.jobFrequency,
      jobFrequencyUnit: values.jobFrequencyUnit,
      query: values.query,
      enabled: values.enabled,
      logVerbosity: values.logVerbosity,
      definedNextRun: values.definedNextRun,
    };

    if (isAddJob) {
      const workspaceId = await getWorkspaceId(values.query);
      saveNewScheduledQuery({ ...createRequest, workspaceId: workspaceId });
    } else {
      const updateRequest: IUpdateScheduledQuery = {
        ...createRequest,
        id: scheduledQueryDetails.id,
        workspaceId: scheduledQueryDetails.workspaceId,
      };
      saveUpdatedScheduledQuery(updateRequest);
    }
  };

  async function onDelete() {
    const response = await deleteJob(scheduledQueryDetails);
    setShowDeleteJobModal(false);

    if (!response.error) {
      ToastrSuccess(
        jobsConstants.JOB_SUCCESSFULLY_DELETED_TITLE,
        `Your scheduled query '${scheduledQueryDetails.name}' was successfully deleted.`,
      );
      navigate("/jobs");
    }
  }

  if (isJobsProcessing || loadingJobDetails) {
    return <Loader />;
  }

  return (
    <div
      data-testid="card-edit-scheduled-query"
      className="edit-scheduled-query"
    >
      <Formik
        innerRef={formikRef}
        initialValues={scheduledQueryDetails}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <TitleWithBackButton
                  title={isAddJob ? "Add Job" : "Edit Job"}
                  customNavigate={() => navigate("/jobs")}
                />
              </Col>
              <Col className="align-items-start justify-content-end">
                <CDataButton
                  buttonType={ButtonType.Secondary}
                  data-testid="button-delete"
                  onClick={() => setShowDeleteJobModal(true)}
                  disabled={isAddJob}
                  className="card-actions me-1"
                >
                  <i className="fa fa-solid fa-times me-1" />
                  Delete
                </CDataButton>
                <CDataButton
                  buttonType={ButtonType.Primary}
                  data-testid="button-save-changes"
                  className="card-actions"
                  type="submit"
                  disabled={!unsavedChanges}
                >
                  <i className="fa fa-solid fa-floppy-disk fa-md align-middle add-connection-icon no-pointer-event" />
                  Save Changes
                </CDataButton>
              </Col>
            </Row>

            {values?.status?.status === JobStatus.JOB_FAILED ? (
              <ErrorNotificationBanner
                jobDetails={scheduledQueryDetails}
                errorLog={errorLog}
              />
            ) : null}
            <ScheduledQueryHeaderCard setUnsavedChanges={setUnsavedChanges} />

            <Row>
              <Col className="align-items-start flex-column">
                <ScheduledQueryDetailsCard
                  setUnsavedChanges={setUnsavedChanges}
                />
                <SQLCodeCard queryDetails={scheduledQueryDetails} />
              </Col>
              <Col className="align-items-start flex-column">
                <RunHistoryCard<IScheduledQuery> />
                <AdvancedSettingsCard<IScheduledQuery>
                  jobType={JobType.ScheduledQuery}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <div
        hidden={!isSavingNew && !isUpdatingExisting && !gettingWorkspaceList}
      >
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>

      <DeleteJobModal
        isBulk={false}
        isDisplayed={showDeleteJobModal}
        onDelete={onDelete}
        setIsDisplayed={setShowDeleteJobModal}
      />
      <PromptWithModal
        when={unsavedChanges}
        navigate={(path: string) => navigate(path)}
      />
    </div>
  );
}
