import { useMutation } from "@tanstack/react-query";
import { ToastrError } from "../../services/toastrService";
import { listScheduledQueries } from "./api/listScheduledQueries";
import { runScheduledQuery } from "./api/runScheduledQuery";
import { toggleCacheJob } from "./api/toggleCacheJob";
import { toggleScheduledQuery } from "./api/toggleScheduledQuery";
import { deleteScheduledQueries } from "./api/deleteScheduledQueries";
import { deleteCacheJobs } from "./api/deleteCacheJobs";
import { deleteSingleJob } from "./api/deleteSingleJob";
import { batchRunCacheJobs } from "./api/batchRunCacheJobs";
import { batchRunScheduledQueries } from "./api/batchRunScheduledQueries";
import { runCacheJob } from "../../api/CacheJobs/runCacheJob";
import { listCacheJobs } from "./api/listCacheJobs";
import { getJobErrorLogs } from "./api/getJobErrorLogs";
import { getDownloadQueryLogs } from "../logs/api/downloadQueryLogs";

export const useJobsFunctions = () => {
  const { mutateAsync: getCachedJobsList, isPending: loadingCachedJobs } =
    useMutation({
      mutationKey: [`/cacheJobs/list`],
      mutationFn: listCacheJobs,
      onError: (error) =>
        ToastrError(
          "An error occurred when retrieving the list of cache jobs.",
          error.message,
        ),
    });

  const {
    mutateAsync: getScheduledQueryList,
    isPending: loadingScheduledQueries,
  } = useMutation({
    mutationKey: ["/scheduledquery/list"],
    mutationFn: listScheduledQueries,
    onError: (error) =>
      ToastrError(
        "An error occurred when retrieving the list of scheduled queries.",
        error.message,
      ),
  });

  const {
    mutateAsync: runScheduledQueryAsync,
    isPending: runningScheduledQuery,
  } = useMutation({
    mutationKey: ["/scheduledquery/runBatch"],
    mutationFn: runScheduledQuery,
    onError: (error) => {
      ToastrError(
        "An error occurred when running the scheduled query.",
        error.message,
      );
    },
  });

  const { mutateAsync: runCacheJobAsync, isPending: runningCacheJob } =
    useMutation({
      mutationKey: ["/cacheJobs/run"],
      mutationFn: runCacheJob,
      onError: (error) => {
        ToastrError("Failed to run Cache job.", error.message);
      },
    });

  const { mutateAsync: tableToggleCacheJob, isPending: togglingCacheJob } =
    useMutation({
      mutationKey: ["/cacheJobs/toggle"],
      mutationFn: toggleCacheJob,
      onError: (error) => {
        ToastrError(
          "An error occurred when toggling the caching job.",
          error.message,
        );
      },
    });

  const {
    mutateAsync: tableToggleScheduledQuery,
    isPending: togglingScheduledQuery,
  } = useMutation({
    mutationKey: ["/scheduledquery/toggle"],
    mutationFn: toggleScheduledQuery,
    onError: (error) => {
      ToastrError(
        "An error occurred when toggling the scheduled query.",
        error.message,
      );
    },
  });

  const { mutateAsync: bulkDeleteCacheJobs, isPending: deletingCacheJobs } =
    useMutation({
      mutationKey: ["cacheJobs/delete"],
      mutationFn: deleteCacheJobs,
      onError: (error) =>
        ToastrError(
          "An error occurred when deleting the caching jobs.",
          error.message,
        ),
    });

  const {
    mutateAsync: bulkDeleteScheduledQueries,
    isPending: deletingScheduledQueries,
  } = useMutation({
    mutationKey: ["scheduledquery/delete"],
    mutationFn: deleteScheduledQueries,
    onError: (error) =>
      ToastrError(
        "An error occurred when deleting the scheduled queries.",
        error.message,
      ),
  });

  const { mutateAsync: deleteJob, isPending: deletingSingleJob } = useMutation({
    mutationKey: ["jobs/delete"],
    mutationFn: deleteSingleJob,
    onError: (error) =>
      ToastrError(
        "An error occurred when deleting the selected job.",
        error.message,
      ),
  });

  const { mutateAsync: batchRunCacheJobsAsync, isPending: runningCacheJobs } =
    useMutation({
      mutationKey: ["/cacheJobs/run/batch"],
      mutationFn: batchRunCacheJobs,
      onError: (error) =>
        ToastrError(
          "An error occurred when running the caching jobs.",
          error.message,
        ),
    });

  const {
    mutateAsync: batchRunScheduledQueriesAsync,
    isPending: runningScheduledQueries,
  } = useMutation({
    mutationKey: ["/scheduledquery/run/batch"],
    mutationFn: batchRunScheduledQueries,
    onError: (error) =>
      ToastrError(
        "An error occurred when running the scheduled queries.",
        error.message,
      ),
  });

  const { mutateAsync: getJobErrorLogsAsync, isPending: gettingJobErrorLogs } =
    useMutation({
      mutationKey: ["/jobs/log/list"],
      mutationFn: getJobErrorLogs,
      meta: {
        errorMessage:
          "An error occurred when retrieving job run failure details.",
      },
    });

  const {
    mutateAsync: downloadJobErrorLogsAsync,
    isPending: downloadingJobErrorLogs,
  } = useMutation({
    mutationKey: ["/log/query/stream/{queryId}"],
    mutationFn: getDownloadQueryLogs,
    meta: {
      errorMessage: "Cannot download logs due to the following error:",
    },
  });

  const isJobsProcessing =
    loadingScheduledQueries ||
    runningScheduledQuery ||
    runningCacheJob ||
    togglingCacheJob ||
    togglingScheduledQuery ||
    deletingSingleJob ||
    deletingCacheJobs ||
    deletingScheduledQueries ||
    runningCacheJobs ||
    runningScheduledQueries ||
    loadingCachedJobs ||
    gettingJobErrorLogs ||
    downloadingJobErrorLogs;

  return {
    getCachedJobsList,
    getScheduledQueryList,
    runScheduledQueryAsync,
    runCacheJobAsync,
    tableToggleCacheJob,
    tableToggleScheduledQuery,
    deleteJob,
    bulkDeleteCacheJobs,
    bulkDeleteScheduledQueries,
    batchRunCacheJobsAsync,
    batchRunScheduledQueriesAsync,
    getJobErrorLogsAsync,
    downloadJobErrorLogsAsync,
    isJobsProcessing,
  };
};
