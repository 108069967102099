import { IQueryResult } from "src/models";
import { IServiceError } from "src/models/IServiceError";
import { cdataFetch } from "../cdataFetch";

export function runCacheJob(jobId: string) {
  return cdataFetch<IQueryResult | IServiceError>({
    method: "POST",
    url: `/cacheJobs/run/${jobId}`,
  });
}
